import React from 'react'
import Container from '@material-ui/core/Container'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ContactFailedPage = () => (
  <Layout>
    <SEO title="Contact Form Send Succed" />
    <Container component="main" maxWidth="md">
      <h1>Thank you for Choosing Us</h1>
      <p>We will reply to you as soon as possible.</p>
    </Container>
  </Layout>
)

export default ContactFailedPage
